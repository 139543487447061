<template>
  <div class="associated">
    <div class="associated_top">
      <div style="flex: 1"></div>
      <div class="associated_img">
        <img :src="companyinfo.coverImage" alt="" />
        <span style="padding-left: 10px">{{ companyinfo.partnerName }}</span>
      </div>
      <div style="flex: 1" class="account_cycle" @click="beforeAccountDialog(id)"></div>
    </div>
    <div class="switch_tab">
      <div style="padding-left: 20px">
        <el-button type="primary" @click="addaccount()">新增角色</el-button>
      </div>
      <div class="label_management">
        <el-table
        v-loading="loading"
          :data="listData"
          stripe
          @sort-change="changeSort"
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="ID" align="center" prop="id"></el-table-column>

          <el-table-column label="角色名" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.roleName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="角色描述" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.description }}</span>
            </template>
          </el-table-column>

          <el-table-column label="用户数量" prop="userCount" sortable align="center"></el-table-column>

          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <!-- slot-scope="scope" -->
            <template slot-scope="scope">
              <el-button style="color: #146aff" type="text" @click="rolePre(scope.row)"
                >权限分配
              </el-button>
              <el-button style="color: #146aff" type="text" @click="listById(scope.row)"
                >编辑
              </el-button>
              <el-button style="color: #fd5469" type="text" @click="accoutClick(scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>

    <el-dialog
      title="新增"
      :visible.sync="accountTypeDialogVisible"
      width="527px"
      class="account_dialog"
    >
      <el-form label-width="130px" :model="adminUser" :rules="adminrules" ref="accountTypeDialog">
        <el-form-item label="角色名:" prop="roleName">
          <el-input v-model="adminUser.roleName" placeholder="请输入角色名" />
        </el-form-item>
        <div v-if="roleId!=adminUser.id">
            <el-form-item label="是否为最高权限:" prop="notAuthority">
          <!-- <el-radio-group v-model="adminUser.notAuthority">
            <el-radio label="2">是</el-radio>
            <el-radio label="1">否</el-radio>
          </el-radio-group> -->
          <span v-if="adminUser.notAuthority==1">否</span>
          <span v-if="adminUser.notAuthority==2">是</span>
        </el-form-item>
        </div>
       <div v-else>
        <el-form-item label="是否为最高权限:" prop="notAuthority">
          <el-radio-group v-model="adminUser.notAuthority">
            <el-radio :label="2">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
       </div>
        <el-form-item label="角色描述:" prop="description">
          <div class="txtree">
            <el-input
              v-model="adminUser.description"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder="请输入角色描述"
              show-word-limit
            />
          </div>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitAccountTypeForm('accountTypeDialog')"
          >确 定</el-button
        >
        
        
        <el-button @click="accountTypeDialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="分配权限" :visible.sync="rloedialog" width="50%"  class="account_dialog">
     <div   v-loading="loading" >
      <myTree
      v-if="rloedialog"
        :data="menuList"
        :props="defaultProps"
        @check="currentChecked"
        show-checkbox
        expand-on-click-node
        node-key="id"
        :default-checked-keys="currentNode"
        :default-expanded-keys="roleObj.authIdList"
      ></myTree>
     </div>

      <div style="display: flex;justify-content: center; margin-top: 40px">
        <div v-if="isrole">
          <el-button v-if="rolemenuList.length==0" @click="submitFormAuth" type="primary" plain>确 定</el-button>
        <el-button v-else-if="roleObj.roleId!=roleId" @click="editPartnerRoleAuth" type="primary">确 定</el-button>
        <el-button v-else-if="roleObj.roleId==roleId" @click="editRoleAuth" type="primary">确 定</el-button>
        
        </div>
        
        
     <div style="margin-left: 10px;">
      <el-button @click="rloedialog=false">取 消</el-button>
     </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listById,
  getByPartnerId,
  updaterole,
  deleteById,
  saverole,
  addRoleAuth,
  roleNotAuthority,
  getRoleAuth,
  editPartnerRoleAuth,
  editRoleAuth,
  orgRoleGinseng
} from '@/api/channel'
import { getAll } from '@/api/menu'
import myTree from '../../../components/tree'
const defaultRoleObj = Object.freeze({
  authIdList: [],//权限编号集合
   
  partnerId: 0,//角色编号
  roleId: 0//合作伙伴编号
})
export default {
  name: 'rolechannel',
  components: { myTree },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  data() {
    return {
        currentNode:[],
      roleObj: { ...defaultRoleObj },
      queryInfo: {
        partnerId: '',
        pageNum: 1,
        pageSize: 10,
        orders: 1 //序 默认传1 2->用户数升序;3->用户数降序
      },
      companyinfo: {}, //合作伙伴信息
      listData: [],
      total: 0,
      adminUser: {
        description: '', //角色描述
        notAuthority: '1', //是否为最高权限 1->不为最高权限;2->最高权限
        partnerId: '', //合作伙伴编号
        roleChannel: 1, //创建来源 1->总台;2->合作伙伴
        roleName: '' //角色名称
      },
      accountTypeDialogVisible: false, //新增角色
      rloedialog: false, //分配权限
      adminrules: {
        description: [{ required: true, message: '请输入角色描述', trigger: 'blur' }],
        roleName: [{ required: true, message: '请输入角色没咸亨', trigger: 'change' }],
        notAuthority: [{ required: true, message: '请勾选是否是最高权限', trigger: 'change' }]
      },
      defaultProps: {
        children: 'children',
        label: 'authName'
      },
      halfCheckedKeys:[],//收集父id
      menuList: [],//所有权限一览
      rolemenuList:[],//已经分配的角色权限
      roleId:'',//该合作机构最高权限的角色编号
      loading:false,
      isrole:false,//判断是否修改了权限菜单
    }
  },
  created() {
    this.search()
    this.getcompany()
   
    this.roleNotAuthority()
  },
  methods: {
    //根据合作伙伴编号查询该合作机构最高权限的角色编号
   async roleNotAuthority(){
    const query={
        partnerId:this.id
    }
    const {data:res}=await roleNotAuthority(query)
    if(res.resultCode==200){
        this.roleId=res.data
    }
    },
    //查询信息回显
    async getcompany() {
      const res = await getByPartnerId({ id: this.id })
      if (res.data.resultCode == 200) {
        this.companyinfo = res.data.data
      }
    },

    beforeAccountDialog(id) {
      this.currentId = id
      this.accountDialogVisible = true
    },

    //冻结账号
    accoutClick(row) {
      const query = {
        roleId: row.id
      }
      this.$alert('您是否确认删除角色？')
        .then(function () {
          return deleteById(query)
        })
        .then(() => {
          this.search()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    },
    async search() {
      this.loading=true
      this.queryInfo.partnerId = this.id
      const res = await orgRoleGinseng(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.loading=false
      }
    },
    //查询菜单所有权限一览
    async getAll(id) {
      
      const res = await getAll()
      if (res.data.resultCode == 200) {
        this.menuList = res.data.data
        this.searchRoleById(id)
       
      }
    },
    //查询最高权限菜单
    async getroleId(id) {
      
      const res = await getRoleAuth({roleId:this.roleId})
      if (res.data.resultCode == 200) {
        this.menuList = res.data.data
        this.searchRoleById(id)
        
      }
    },
    //选择菜单
    currentChecked(nodeObj, SelectedObj) {
   
      this.roleObj.authIdList= SelectedObj.checkedKeys
      this.halfCheckedKeys=SelectedObj.halfCheckedKeys
      this.isrole=true
    },
    //第一次分配权限
    async submitFormAuth() {
      this.loading=true
      this.roleObj.authIdList=this.roleObj.authIdList.concat(this.halfCheckedKeys)
      
      const { data: res } = await addRoleAuth(this.roleObj)
      if (res.resultCode === 200) {
        this.$message.success('权限新增成功')
        this.rloedialog = false
        this.loading=false
        this.isrole=false
      } else {
        this.$message.error(res.message)
        this.rloedialog = false
        this.loading=false
        this.isrole=false
      }

      this.search()
    },
    //普通角色进行更新修改角色
    async editPartnerRoleAuth() {
      this.roleObj.authIdList=this.roleObj.authIdList.concat(this.halfCheckedKeys)
      this.loading=true
      const { data: res } = await editPartnerRoleAuth(this.roleObj)
      if (res.resultCode === 200) {
        this.$message.success('权限更新成功')
        this.rloedialog = false
        this.isrole=false
        this.loading=false
      } else {
        this.$message.error(res.message)
        this.rloedialog = false
        this.loading=false
        this.isrole=false
      }

      this.search()
    },
    //最高角色权限角色进行更新修改角色
    async editRoleAuth() {
      this.roleObj.authIdList=this.roleObj.authIdList.concat(this.halfCheckedKeys)
      this.loading=true
      const { data: res } = await editRoleAuth(this.roleObj)
      if (res.resultCode === 200) {
        this.$message.success('权限更新成功')
        this.rloedialog = false
        this.loading=false
        this.isrole=false
      } else {
        this.$message.error(res.message)
        this.rloedialog = false
        this.loading=false
        this.isrole=false
      }

      this.search()
    },
    
    
    //新增编辑角色
    submitAccountTypeForm(formName) {
      this.adminUser.partnerId = this.id
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.adminUser.id) {
            saverole(this.adminUser).then(({ data: res }) => {
              if (res.resultCode === 200) {
                this.search()
                this.getroleId()
                this.accountTypeDialogVisible = false
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            updaterole(this.adminUser).then(({ data: res }) => {
              if (res.resultCode === 200) {
                this.search()
                this.accountTypeDialogVisible = false
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    },

    //新增角色
    addaccount() {
      this.accountTypeDialogVisible = true
      this.adminUser={
        description: '', //角色描述
        notAuthority: '1', //是否为最高权限 1->不为最高权限;2->最高权限
        partnerId: '', //合作伙伴编号
        roleChannel: 1, //创建来源 1->总台;2->合作伙伴
        roleName: '' //角色名称
      }
    },
    //编辑角色
    async listById(row) {
      const query = { id: row.id }
      const { data: res } = await listById(query)
      if (res.resultCode == 200) {
        this.adminUser = res.data
        this.accountTypeDialogVisible = true
      }
    },
    //权限分配
  async  rolePre(row) {
   
    this.loading=true
        this.roleObj= { ...defaultRoleObj },
      this.roleObj.roleId=row.id
      //判断是否含有最高权限角色
      if(!this.roleId){
        this.$message.warning('请先添加最高权限角色！')
        return
      }
      if(this.roleId==row.id){//最高权限
        this.getAll(row.id)
      }else{
         this.getroleId(row.id)
      }
      // await this.searchRoleById(row.id)
      this.roleObj.partnerId=this.id*1
      
    },
    //回显
    async searchRoleById(roleId) {
     
      this.roleObj.authIdList = []
      this.currentNode = []
      const { data: res } = await getRoleAuth({ roleId })
     
      if (res.resultCode === 200) {
      this.rolemenuList=res.data
      
        this.recursionId(res.data)
        
      } else {
        this.$message.warning(res.message)
      }
    },
      // 机构获取权限展示key
      recursionId(arr) {
       
      arr.forEach((item) => {
      if(item.children!=null){
        if (item.children.length == 0) {
        
          this.currentNode.push(item.id)
          this.roleObj.authIdList.push(item.id)
        } else  {
          this.recursionId(item.children)
        }
      }else{
        this.currentNode.push(item.id)
          this.roleObj.authIdList.push(item.id)
      }
        
      })
      this.loading=false
      this.rloedialog = true
    
     
    },
  
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'userCount') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orders = 2
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 3
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 1
          this.search()
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el_upload .el-form-item {
  margin-bottom: 0px;
}
::v-deep .name_form .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -54px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-step__title {
  font-size: 14px;
  line-height: 38px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 556px;
}
::v-deep .name_form .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 496px;
}

.associated {
  background: #ffffff;
  .img_commer {
    display: flex;
    border: 1px solid #146aff;
    border-radius: 39px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #146aff;
    }
    .color_img {
      background: #146aff;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .img_commers {
    display: flex;
    border: 1px solid #ff9c00;
    border-radius: 39px;
    margin: 0px 10px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #ff9c00;
    }
    .color_img {
      background: #ff9c00;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .associated_top {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-direction: column;
    // padding: 30px 0px 0px 0px;
    .account_cycle {
      text-align: right;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      /* 灰色字体 */

      color: #969495;
    }
    .associated_img {
      display: flex;
      align-items: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;

      color: #151515;
      img {
        width: 67px;
        height: 54px;
        border-radius: 5px;
      }
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;

    .essential_information {
      padding: 30px 40px;
      .general_input {
        width: 845px;
        border: 1px solid #d4d4d4;
        padding: 14px 16px;

        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .img_tips {
        position: relative;
        top: -10px;
        color: #6ba1ff;
      }

      h4 {
        color: #146aff;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .label_management {
    padding: 10px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: left;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
</style>
